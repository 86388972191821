import React from 'react'
import Layout from '../components/layout'
import Lecture from "../components/lecture";
import { Col, Row } from "react-bootstrap";
import SEO from "../components/seo";
import { sortData } from '../utils';

export default function Software({ data }) {
  return (
    <Layout>
      <SEO title="Software"></SEO>
      <Row className="mb-3">
        <Col>
          <h2 className='text-capitilize'>Software</h2>
        </Col>
      </Row>
      <Row>
        <Col>
          {data.allMarkdownRemark.edges.sort(sortData).map(({ node }, index) => (
            <Lecture node={node} key={index}></Lecture>
          ))
          }
        </Col>
      </Row>
    </Layout>
  )
}


export const query = graphql`
query MyQuery {
    allMarkdownRemark(filter: {frontmatter: {layout: {eq: "lecture"}}}) {
      edges {
        node {
          id
          fields {
            slug
          }
          frontmatter {
            title
            lecturefiles
            summary
            desc
            date
          }
          html
        }
      }
    }
  }
  
`