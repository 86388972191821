
import React from 'react'

const Lecture = ({ node }) => {
    console.log(node);
    return (
        <section>
            <h4 className='text-capitilize'>{node.frontmatter.title}</h4>
            <p>
                {node.frontmatter.summary}
            </p>
            <div dangerouslySetInnerHTML={{ __html: node.html }}></div>
            <hr />
        </section>
    )
}

export default Lecture
